import "./footer.css";
import logo from "../../assets/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div
        className="container"
        style={{ position: "relative", textAlign: "center" }}
      >
        <a href="/">
          <img src={logo} className="logo-footer" />
        </a>
        <div className="links d-flex justify-content-center">
          <ul>
            <li>
              <a href="/">Strona główna</a>
            </li>
            <li>
              <a href="/company">Firma</a>
            </li>
            <li>
              <a href="/products-and-services">Produkty & Usługi</a>
            </li>
            <li>
              <a href="/contact">Kontakt</a>
            </li>
          </ul>
        </div>
        <p id="copyright" style={{ margin: "0" }}>
          MVA Industries - Copyright &copy; {new Date().getFullYear()}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
