import Navbar from "../../components/Navbar/Navbar";
import valves from "../../assets/1_valves Termovents.png";
import Footer from "../../components/Footer/Footer";
import { Content, MainContent } from "../../components/Content/Content";
import DecoratingImage from "../../components/DecoratingImage";
import {
  KnifeGateValveABContent,
  KnifeGateValveCContent,
  KnifeGateValveDContent,
  KnifeGateValveGHContent,
  KnifeGateValveGLContent,
  ValvesContents,
} from "./ValvesContents";
import {
  controlValveContent,
  forgedGateValvesContent,
  gateValvesContent,
  globeLiftCheckValvesContent,
  globeStopValveContent,
  highPressureControlGlobeValveContent,
  highPressureGateValveContent,
  highPressureGlobeValveContent,
  highPressureGlobeValvesContent,
  knifeGateValveAContent,
  needleValveContent,
  swingCheckValvesContent,
  waferTypeSwingCheckValveContent,
} from "./ValvesContentsData";

const options = [
  {
    header: { headerName: "Zawory" },
    content: [
      { name: "Zawór zaporowy prosty", link: "/globe-stop-valve" },
      { name: "Zawory regulacyjne", link: "/control-valve" },
      {
        name: "Zawór zaporowy wysokociśnieniowy",
        link: "/high-pressure-globe-valve",
      },
      {
        name: "Zawór regulacyjny wysokociśnieniowy",
        link: "high-pressure-control-globe-valve",
      },
      { name: "Zawór iglicowy", link: "/needle-valve" },
    ],
  },
  {
    header: { headerName: "Zawory zwrotne" },
    content: [
      { name: "Zawór zwrotny grzybkowy", link: "/globe-lift-check-valves" },
      { name: "Zawór zwrotny klapowy", link: "/swing-check-valves" },
      {
        name: "Zawór zwrotny grzybkowy wysokociśnieniowy",
        link: "/high-pressure-globe-valves",
      },
      {
        name: "Zawór zwrotny typ wafer",
        link: "/wafer-type-swing-check-valve",
      },
    ],
  },
  {
    header: { headerName: "Zasuwy klinowe" },
    content: [
      { name: "Zasuwa klinowa", link: "/gate-valves" },
      { name: "Kuta zasuwa klinowa", link: "/forged-gate-valves" },
      {
        name: "Zasuwa klinowa PN 250 ÷ PN 400",
        link: "/high-pressure-gate-valve",
      },
    ],
  },
  {
    header: { headerName: "Zasuwy nożowe" },
    content: [
      { name: "Zasuwa nożowa –  Typ A", link: "/knife-gate-valve-a" },
      { name: "Zasuwa nożowa –  Typ AB", link: "/knife-gate-valve-ab" },
      { name: "Zasuwa nożowa – Typ D", link: "/knife-gate-valve-d" },
      { name: "Zasuwa nożowa – Typ GH", link: "/knife-gate-valve-gh" },
      { name: "Zasuwa nożowa – Typ GL", link: "/knife-gate-valve-gl" },
      { name: "Zasuwa nożowa – Typ C", link: "/knife-gate-valve-c" },
    ],
  },
  {
    header: { headerName: "Przepustnice" },
    content: [
      { name: "opcja1", link: "#" },
      { name: "opcja2", link: "#" },
    ],
  },
  {
    header: { headerName: "Electric Actuators" },
    content: [
      { name: "Napędy wieloobrotowe SA/SAR", link: "/" },
      { name: "Napędy wieloobrotowe SAV/SARV", link: "/" },
      { name: "Napędy wieloobrotowe SAEx i SAREx", link: "/" },
      { name: "Napędy niepełnoobrotowe SQ/SQR", link: "/" },
      { name: "Napędy niepełnoobrotowe ED/EQ", link: "/" },
      { name: "Sterowniki AUMA MATIC", link: "/" },
      { name: "Sterowniki AUMATIC", link: "/" },
    ],
  },
  {
    header: { headerName: "Napędy pneumatyczne" },
    content: [
      { name: "opcja1", link: "#" },
      { name: "opcja2", link: "#" },
    ],
  },
];

export const ValvesPage = () => {
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <MainContent heading="Armatura przemysłowa" />
      </Content>
      <Footer />
    </>
  );
};

export const GlobeStopValvePage = () => {
  options[0].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={globeStopValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const ControlValvePage = () => {
  options[0].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={controlValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGlobeValvePage = () => {
  options[0].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGlobeValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureControlGlobeValvePage = () => {
  options[0].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureControlGlobeValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const NeedleValvePage = () => {
  options[0].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={needleValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const GlobeLiftCheckValvesPage = () => {
  options[1].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={globeLiftCheckValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const SwingCheckValvesPage = () => {
  options[1].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={swingCheckValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGlobeValvesPage = () => {
  options[1].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGlobeValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const WaferTypeSwingCheckValvePage = () => {
  options[1].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={waferTypeSwingCheckValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const GateValvesPage = () => {
  options[2].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={gateValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const ForgedGateValvesPage = () => {
  options[2].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={forgedGateValvesContent} />
      </Content>
      <Footer />
    </>
  );
};

export const HighPressureGateValvePage = () => {
  options[2].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={highPressureGateValveContent} />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveAPage = () => {
  options[3].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <ValvesContents content={knifeGateValveAContent} />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveABPage = () => {
  options[3].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveABContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveDPage = () => {
  options[3].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveDContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveGHPage = () => {
  options[3].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveGHContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveGLPage = () => {
  options[3].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveGLContent />
      </Content>
      <Footer />
    </>
  );
};

export const KnifeGateValveCPage = () => {
  options[3].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={valves} />
      <Content options={options}>
        <KnifeGateValveCContent />
      </Content>
      <Footer />
    </>
  );
};
