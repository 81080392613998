import "./tile.css";
import valves from "../../assets/valves.jpg";
import marine from "../../assets/marine.jpg";
import rail from "../../assets/rail.jpg";
import industry from "../../assets/industry.jpg";

export const Tile = ({ link, heading, image }) => {
  return (
    <div className="col-lg-2 tile">
      <a href={link}>
        <div className="tile-heading">{heading}</div>
        <div className="tile-image">
          <img src={image} alt={heading} />
        </div>
      </a>
    </div>
  );
};

export const Tiles = () => {
  return (
    <div className="container tiles">
      <div className="row d-flex justify-content-center">
        <Tile
          link="/valves-and-automation"
          heading="Armatura przemysłowa"
          image={valves}
        />
        <Tile link="/marine" heading="Przemysł Morski" image={marine} />
        <Tile link="/rail" heading="Kolej" image={rail} />
        <Tile link="#" heading="Przemysł Ogólny" image={industry} />
        {/* <Tile link="#" heading="Oil & Gas Valves" image={warsaw1} /> */}
      </div>
    </div>
  );
};
