import { ContentHeader } from "../../components/Content/ContentHeader";
import "../../components/Content/content.css";
import knifeGateValveAB from "../../assets/4.2.png";
import knifeGateValveD from "../../assets/4.3.png";
import knifeGateValveGH from "../../assets/4.4.png";
import knifeGateValveGL from "../../assets/4.5.png";
import knifeGateValveC from "../../assets/4.6.png";

export const ValvesContents = ({ content }) => {
  return (
    <>
      <ContentHeader
        headerContent={content.headerContent}
        image={content.image}
      />
      {content.mainContent.Design && (
        <>
          <h1 className="section-header">Konstrukcja</h1>
          <ul className="check-list">
            {content.mainContent.Design.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Applications && (
        <>
          <h1 className="section-header">Aplikacje</h1>
          <ul className="check-list">
            {content.mainContent.Applications.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Media && (
        <>
          <h1 className="section-header">Media</h1>
          <ul className="check-list">
            {content.mainContent.Media.map((li, index) => {
              if (index === 0) {
                return <p key={li}>{li}</p>;
              } else {
                return <li key={li}>{li}</li>;
              }
            })}
          </ul>
        </>
      )}
      {content.mainContent.Pressure && (
        <>
          <h1 className="section-header">Ciśnienie i temperatura</h1>
          <ul className="check-list">
            {content.mainContent.Pressure.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Materials && (
        <>
          <h1 className="section-header">Materiały</h1>
          <ul className="check-list">
            {content.mainContent.Materials.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Options && (
        <>
          <h1 className="section-header">Wykonanie</h1>
          <ul className="check-list">
            {content.mainContent.Options.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.Advantages && (
        <>
          <h1 className="section-header">Zalety</h1>
          <ul className="check-list">
            {content.mainContent.Advantages.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.IndustrialApplications && (
        <>
          <h1 className="section-header">Zastosowania przemysłowe</h1>
          <ul className="check-list">
            {content.mainContent.IndustrialApplications.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.ProductDescription && (
        <>
          <h1 className="section-header">Opis produktu</h1>
          <strong>{content.mainContent.ProductDescription.header}</strong>
          <p
            style={{
              fontSize: "large",
              marginTop: "1.2rem",
              marginBottom: "1.2rem",
            }}
          >
            {content.mainContent.ProductDescription.description}
          </p>
        </>
      )}
      {content.mainContent.Sizes && (
        <>
          <h1 className="section-header">Średnice</h1>
          <ul className="check-list">
            {content.mainContent.Sizes.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
      {content.mainContent.WorkingPressures && (
        <>
          <h1 className="section-header">Ciśnienia robocze</h1>
          <ul className="check-list">
            {content.mainContent.WorkingPressures.map((li) => {
              return <li key={li}>{li}</li>;
            })}
          </ul>
        </>
      )}
    </>
  );
};

export const KnifeGateValveABContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Zasuwa nożowa –  Typ AB" }}
        image={knifeGateValveAB}
      />
      <h1 className="section-header">Zastosowanie</h1>
      <ul className="check-list">
        <li>Przemysł spożywczy</li>
        <li>Przemysł chemiczny</li>
        <li>Uzdatnianie wody</li>
        <li>Przemysł celulozowo-papierniczy</li>
        <li>Tamy i projekty hydrologiczne</li>
      </ul>
      <h1 className="section-header">Opis produktu</h1>
      <strong>Zawory szlamowe, zasuwa nożowa</strong>
      <p>
        Zawory szlamowe, dwukierunkowa zasuwa nożowa o konstrukcji waflowej.
        Jednoczęściowy odlewany korpus. Zapewnia wysokie prędkości przepływu
        przy niskim spadku ciśnienia. Dostępne różne materiały do siedzenia i
        pakowania.
      </p>
      <h1 className="section-header">
        Zaprojektowany do zastosowań takich jak:
      </h1>
      <ul className="check-list">
        <li>Rośliny chemiczne</li>
        <li>Pompowanie</li>
        <li>Przemysł spożywczy</li>
        <li>Oczyszczanie ścieków</li>
      </ul>
      <h1 className="section-header">Średnice</h1>
      <ul className="check-list">
        <li>Standardowe średnice: od DN50 do DN 600</li>
        <li>Większe średnice są dostępne na zamówienie</li>
      </ul>
      <h1 className="section-header">Ciśnienia robocze</h1>
      <ul className="check-list">
        <li>DN 50-DN200 = 10 bar</li>
        <li>DN 250-DN400 = 6 bar</li>
        <li>DN 450 = 5 bar</li>
        <li>DN 500 = 4 bar</li>
        <li>DN 600 = 3 bar</li>
      </ul>
    </>
  );
};

export const KnifeGateValveDContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Zasuwa nożowa –  Typ D" }}
        image={knifeGateValveD}
      />
      <h1 className="section-header">Zastosowanie</h1>
      <ul className="check-list">
        <li>Przemysł spożywczy</li>
        <li>Przemysł chemiczny</li>
        <li>Cement</li>
        <li>Generacja energii</li>
        <li>Uzdatnianie wody</li>
        <li>Przemysł celulozowo-papierniczy</li>
      </ul>
      <h1 className="section-header">Opis produktu</h1>
      <p>
        Zasuwa nożowa jednokierunkowa kołnierzowa (na zamówienie opcja
        dwukierunkowa), przeznaczona do zastosowań wysokociśnieniowych, z
        uszczelką samoczyszczącą. Jednoczęściowy korpus odlewany ciśnieniowo z
        przykręcaną pokrywą i klinami gwarantującymi szczelność. Zapewnia
        wysokie prędkości przepływu przy niskim spadku ciśnienia. Dostępne różne
        materiały uszczelniające i opakowaniowe.
      </p>
      <p>Posiada strzałkę na korpusie wskazującą kierunek przepływu.</p>
      <h1 className="section-header">Wykorzystanie</h1>
      <p>
        Zasuwa nadaje się do pracy z czystymi cieczami lub cieczami o stężeniu
        ciał stałych: Suszarnie, Przemysł papierniczy, Uzdatnianie wody, Zakłady
        chemiczne Sektor spożywczy, górnictwo, wydobycie ropy naftowej, osady.
      </p>
      <h1 className="section-header">Średnice</h1>
      <ul className="check-list">
        <li>DN50 do DN1200</li>
        <li>Większe średnice są dostępne na zamówienie</li>
      </ul>
      <h1 className="section-header">Ciśnienia robocze</h1>
      <ul className="check-list">
        <li>PN 2,5 to PN 100</li>
        <li>Każda zasuwa jest zaprojektowana zgodnie z warunkami pracy</li>
      </ul>
    </>
  );
};

export const KnifeGateValveGHContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Zasuwa nożowa –  Typ GH" }}
        image={knifeGateValveGH}
      />
      <h1 className="section-header">Aplikacje przemysłowe</h1>
      <ul className="check-list">
        <li>Górnicze zawory szlamowe</li>
        <li>Przemysł chemiczny</li>
        <li>Cement</li>
        <li>Generacja energii</li>
      </ul>
      <h1 className="section-header">Opis produktu</h1>
      <strong>Zasuwa nożowa do szlamu</strong>
      <p>
        Dwukierunkowa zasuwa nożowa o konstrukcji waflowej. Jednoczęściowy
        żeliwny korpus „Monoblock”. Brama ze stali nierdzewnej. Dwie gumowe
        tulejki. Zapewnia wysokie natężenie przepływu przy niskim spadku
        ciśnienia.
      </p>
      <h1 className="section-header">Wykorzystanie</h1>
      <p>
        Zasuwa nożowa nadaje się do pracy w przemyśle wydobywczym, w przewodach
        transportu cieczy obciążonych, takich jak: woda z kamieniami, szlamem
        itp. oraz ogólnie stosowana jest do cieczy ściernych w przemyśle
        chemicznym i ściekach.
      </p>
      <ul className="check-list">
        <p>Zaprojektowany do następujących zastosowań:</p>
        <li>Górnictwo</li>
        <li>Oczyszczanie ścieków</li>
        <li>Elektrownie</li>
        <li>Zakłady chemiczne</li>
        <li>Sektor energetyczny</li>
        <li>Elektrociepłownie</li>
      </ul>
      <h1 className="section-header">Średnice</h1>
      <ul className="check-list">
        <li>DND80‐3″ to ND900‐36″</li>
        <li>Większe średnice dostępne są na zamówienie.</li>
      </ul>
      <h1 className="section-header">Ciśnienia robocze</h1>
      <ul className="check-list">
        <li>ASA 150 DN80-DN600 = 21 bar</li>
        <li>PN 25 DN80-DN600 = 25 bar</li>
        <li>ASA 300/PN40 DN80-DN600 = 40 bar</li>
        <p>
          Ciśnienia wskazane w tabeli można zastosować w dowolnym z dwóch
          kierunków zaworu.
        </p>
      </ul>
    </>
  );
};

export const KnifeGateValveGLContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Zasuwa nożowa –  Typ GL" }}
        image={knifeGateValveGL}
      />
      <h1 className="section-header">Aplikacje przemysłowe</h1>
      <ul className="check-list">
        <li>Górnicze zawory szlamowe</li>
        <li>Przemysł chemiczny</li>
        <li>Cement</li>
        <li>Generacja energii</li>
      </ul>
      <h1 className="section-header">Opis produktu</h1>
      <strong>Zasuwy szlamowe</strong>
      <p>
        Główną cechą tego zaworu jest to, że zapewnia pełny ciągły przepływ.
        Oznacza to, że w pozycji otwartej nie tworzy wnęk i nie ma turbulencji w
        płynie.
      </p>
      <ul className="check-list">
        <li>Dwukierunkowa zasuwa nożowa o konstrukcji waflowej.</li>
        <li>Monoblokowy, jednoczęściowy żeliwny korpus.</li>
        <li>Brama ze stali nierdzewnej. Dwa gumowe rękawy.</li>
        <li>
          Zapewnia wysokie natężenia przepływu przy niskim spadku ciśnienia.
        </li>
        <li>Dostępne różne materiały siedzisk.</li>
      </ul>
      <h1 className="section-header">Wykorzystanie</h1>
      <p>
        Zasuwy nożowe przeznaczone są do pracy w przemyśle wydobywczym, w
        przewodach transportu płynów obciążonych, takich jak: woda z kamieniami,
        szlam, oraz ogólnie do cieczy ściernych w przemyśle chemicznym i
        ściekach.
      </p>
      <ul className="check-list">
        <p>Zaprojektowany do następujących zastosowań::</p>
        <li>Górnictwo</li>
        <li>Oczyszczanie ścieków</li>
        <li>Elektrownie</li>
        <li>Rośliny chemiczne</li>
        <li>Sektor energetyczny</li>
        <li>Elektrociepłownie</li>
      </ul>
      <h1 className="section-header">Średnice</h1>
      <ul className="check-list">
        <li>DN50 to DN1500</li>
        <li>Większe średnice są dostępne na zamówienie.</li>
      </ul>
      <h1 className="section-header">Ciśnienia robocze</h1>
      <ul className="check-list">
        <li>DN50-DN150 = 16 bar</li>
        <li>DN200-DN600 = 10 bar</li>
        <li>DN700-DN900 = 8 bar</li>
        <li>DN1000-DN1200 = 6 bar</li>
        <p>
          Ciśnienia wskazane w tabeli można zastosować w dowolnym z dwóch
          kierunków zaworu.
        </p>
      </ul>
    </>
  );
};

export const KnifeGateValveCContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{ h1Content: "Zasuwa nożowa –  Typ C" }}
        image={knifeGateValveC}
      />
      <h1 className="section-header">Aplikacje przemysłowe</h1>
      <ul className="check-list">
        <li>Przemysł chemiczny</li>
        <li>Generacja energii</li>
        <li>Uzdatnianie wody</li>
        <li>Przemysł celulozowo-papierniczy</li>
        <li>Tamy i projekty hydrologiczne</li>
        <li>Przemysł spożywczy</li>
      </ul>
      <h1 className="section-header">Opis produktu</h1>
      <p>
        Jednokierunkowa zasuwa nożowa w kształcie kwadratu lub prostokąta.
        Produkcja konstrukcji spawanych. Dostępne różne materiały do siedzenia i
        pakowania.
      </p>
      <h1 className="section-header">Wykorzystanie</h1>
      <p>
        Ta zasuwa nożowa nadaje się do ciał stałych, jest również zalecana do
        zastosowań z wyładowaniem grawitacyjnym dla płynów o wysokiej zawartości
        ciał stałych.
      </p>
      <p>Górnictwo, transport masowy, zakłady chemiczne. Przemysł spożywczy.</p>
      <h1 className="section-header">Średnice</h1>
      <ul className="check-list">
        <li>DN50 to DN1500</li>
        <li>Większe średnice są dostępne na zamówienie.</li>
      </ul>
      <h1 className="section-header">Ciśnienia robocze</h1>
      <ul className="check-list">
        <li>Standard: 0,5 bar</li>
        <li>
          W przypadku większych ciśnień proszę wysłać zapytanie do MVA
          Industries
        </li>
      </ul>
    </>
  );
};
