import { ContentHeader } from "../../components/Content/ContentHeader";
import electricalCabinets from "../../assets/electrical_cabinets.jpg";
import interiorWallSystem from "../../assets/interior_wall_system.jpg";
import internalWalls from "../../assets/internal_walls.jpg";
import partitionWalls from "../../assets/partition_walls.jpg";
import driversDesk from "../../assets/drivers_desk.jpg";
import ceilings from "../../assets/ceilings.jpg";
import driversCabDoor from "../../assets/drivers_cab_door.jpg";
import internalDoors from "../../assets/internal_doors.jpg";
import passengerDoor from "../../assets/passenger_door.jpg";
import internalDoorDriversFence from "../../assets/internal_door_drivers_fence.jpg";
import tiltWindow from "../../assets/tilt_window.jpg";
import driversCabWindow from "../../assets/drivers_cab_window.jpg";
import hingedWindow from "../../assets/hinged_window.jpg";
import slidingWindow from "../../assets/sliding_window.jpg";
import dropdownSlidingWindow from "../../assets/dropdown_sliding_window.jpg";

export const ElectricalCabinetsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Szafy elektryczne i zabudowy",
          h3Content: [
            "Wszelkiego rodzaju zabudowy wewnętrzne przy kabinie maszynisty, w których znajdują się systemy elektryczne oraz pneumatyczne.",
          ],
        }}
        image={electricalCabinets}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>wykonane z aluminium</li>
        <li>powłoka ochronna</li>
        <li>malowanie proszkowe</li>
        <li>nowoczesna stylizacja</li>
        <li>malowanie ciekłe</li>
      </ul>
      <h2>Podstawowe dane techniczne:</h2>
      <p>
        Konstrukcje szaf wykonane ze stopów aluminium, montowane zazwyczaj w
        przedziale maszynowym pojazdu. Niewidoczna z zewnątrz część szafy
        stanowi konstrukcję nośną, do której montowane są rozdzielnie oraz inne
        wyposażenie pojazdu. Cześć widoczną zazwyczaj stanowią drzwi. Drzwi w
        zależności od kształtu i wymogów estetycznych, mogą być wykonane jako
        elementy spawane, klejone lub nitowane.
      </p>
      <p>
        Dodatkowo, na drzwiach montowane są materiały podnoszące ich sztywność
        oraz poprawiające właściwości termiczne i akustyczne, jak np. korek,
        pianki melaminowe oraz inne materiały o podobnych właściwościach
        posiadające certyfikaty palnościowe. Wszystkie szafy wyposażone są w
        uziemienia oraz system wentylacji.
      </p>
      <p>
        Istnieje możliwość zastosowania oświetlenia montowanego wewnątrz
        zabudowy.
      </p>
      <h2>Dodatkowe informacje:</h2>
      <ul className="check-list">
        <li>wykonane z aluminium</li>
        <li>nowoczesna stylizacja</li>
      </ul>
      <p>Powłoka ochronna:</p>
      <ul className="check-list">
        <li>malowanie ciekłe</li>
        <li>malowanie proszkowe</li>
      </ul>
    </>
  );
};

export const InteriorWallSystemsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Ścianki z drzwiami do wnętrz pojazdów",
          h3Content: [
            "Ściany wewnętrzne z drzwiami do wszelkiego rodzaju pojazdów dedykowanych dla transportu publicznego. Głównym zastosowaniem tych produktów są pociągi, metro oraz tramwaje.",
          ],
        }}
        image={interiorWallSystem}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h4>
        Klient ma możliwość dopasowania konstrukcji ścian wewnętrznych z
        drzwiami do własnego środowiska – to największy atut tego produktu.
      </h4>
      <p>
        Wszelkie wymagania klienta są uzgadniane na etapie tworzenia
        konstrukcji, co pozwala klientowi na pełny wgląd w produkt przed
        uruchomieniem produkcji seryjnej.
      </p>
      <p>
        Ściany produkowane są jako lekkie aluminiowe konstrukcje z wypełnieniem
        szklanym, blaszanym lub plastrem miodu. Do powierzchni widocznych dla
        pasażerów istnieje możliwość zastosowania różnych materiałów:
      </p>
      <ul className="check-list">
        <li>aluminum</li>
        <li>laminat</li>
      </ul>
      <p>Możliwość zastosowania:</p>
      <ul className="check-list">
        <li>dodatkowe prowadzenie dolne</li>
        <li>kratki wentylacyjne</li>
        <li>fotokomórki</li>
      </ul>
      <h4>Drzwi wyposażone są głównie w napęd elektryczny lub manualny.</h4>
      <p>
        Systemy drzwi wewnętrznych spełniają najbardziej zróżnicowane wymogi
        funkcjonalne wymuszane przez transport publiczny, przy jednoczesnym
        zapewnieniu bezpieczeństwa pasażerów, systemy są łatwe w montażu oraz
        serwisowaniu.
      </p>
      <h4>
        Do produkcji systemów drzwi wewnętrznych używamy stopów aluminium oraz
        materiałów nowoczesnych-hybrydowych, łączących własności wielu różnych
        materiałów, pozwalających uzyskiwać niską masę wyrobu, duże parametry
        wytrzymałościowe, jak również barierę przeciwpożarową.
      </h4>
      <p>
        Systemy w zależności od specyfikacji, mogą występować w wersji
        elektrycznej, pneumatycznej lub manualnej. Systemy drzwi wewnętrznych
        mogą być wykończone jako satynowe, szczotkowane lub wykończone powłokami
        malarskimi, elementami dekoracyjnymi oraz warstwami HPL.
      </p>
    </>
  );
};

export const PartitionWallsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Ściany wewnętrzne",
          h3Content: [
            "Wszelkiego rodzaju ściany do pojazdów transportu publicznego. ",
          ],
        }}
        image={partitionWalls}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>aluminium, szkło, HPL, GRP, honeycomb (plaster-miodu)</li>
        <li>drzwi wahadłowe lub automatyczne</li>
        <li>możliwość łączenia z dodatkowym wyposażeniem</li>
        <li>możliwość zastosowania wielu materiałów</li>
        <li>grubość nawet do 25 mm</li>
        <li>lekka konstrukcja</li>
      </ul>
      <p>
        Ściany wewnętrzne wykonywane są ze szkła i aluminium oraz z laminatów
        HPL. Konstrukcje ścian oraz zabudowań wewnętrznych głównie wykonanych ze
        stopów aluminium, są elementami dekoracyjnymi oraz konstrukcyjnie
        przenoszącym obciążenia. Wyroby te przewidziane są do łączenia oraz
        współpracy z innymi podzespołami pojazdu, np. systemami klimatyzacji,
        szafami elektrycznymi, itp.
      </p>
      <h4>
        W zależności od specyfikacji i walorów estetycznych – do produkcji
        zabudów mogą być użyte stopy aluminium, szkło, warstwy HPL, okleiny,
        honeycomb (plaster miodu), korek oraz tworzywa sztuczne i GRP.
      </h4>
      <p>
        W wykonaniu znajdują się również wersje z dodatkową izolacją akustyczną,
        termiczną lub tłumiącą drgania. Oferujemy wykończenie powierzchni w
        postaci malowania proszkowego lub ciekłego. Na prośbę klienta, w
        zabudowach przygotowywane są również miejsca pod montaż urządzeń
        informacji pasażerskiej.
      </p>
      <h4>
        Ściany wewnętrzne mogą mieć różny design oraz wykończenie powierzchni –
        wszystko według wymagań klienta.
      </h4>
      <p>
        W ścianach, w których design przewiduje zastosowanie drzwi, istnieje
        możliwość użycia systemu wahadłowego z samozamykaczem lub przesuwnego z
        mechanizmem elektrycznym.
      </p>
      <p>
        Ścianki wewnętrzne nie wymagające ognioodporności, cechują się także
        niewielką grubością wynoszącą około 25 mm.{" "}
      </p>
      <h4>
        Ściany wewnętrzne pojazdu produkty stosunkowo lekkie i wytrzymałe ze
        względu na użyte materiały zgodne z obowiązującymi normami.
      </h4>
    </>
  );
};

export const InternalWallsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Zabudowa wewnętrzna",
          h3Content: [
            "Zabudowy wewnętrzne oraz schody z wyposażeniem, przeznaczone do wagonów pasażerskich, jednostek elektrycznych i spalinowych.",
          ],
        }}
        image={internalWalls}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Podstawowe dane techniczne:</h2>
      <p>
        Konstrukcje zabudów wewnętrznych wykonane ze stopów aluminium są
        jednocześnie elementami konstrukcyjnymi (przenoszącymi obciążenia) oraz
        dekoracyjnymi. Wyroby te przewidziane są do łączenia oraz współpracy z
        innymi podzespołami pojazdu, np. systemami klimatyzacji, szafami
        elektrycznymi itp.
      </p>
      <p>
        W zależności od specyfikacji i walorów estetycznych, do produkcji
        zabudów mogą być użyte: stopy aluminium, warstwy HPL, okleiny, honeycomb
        (plaster miodu), korek oraz tworzywa sztuczne i GRP. W wykonaniu
        znajdują się również wersje z dodatkową izolacją akustyczną, termiczną
        lub tłumiącą drgania.
      </p>
      <p>
        Oferujemy wykończenie powierzchni w postaci malowania proszkowego lub
        ciekłego. Na prośbę klienta, w zabudowach przygotowywane są również
        miejsca pod montaż urządzeń informacji pasażerskiej.
      </p>
      <h2>Dodatkowe informacje:</h2>
      <ul className="check-list">
        <li>wykonane z aluminium,</li>
        <li>nowoczesna stylizacja.</li>
      </ul>
      <h2>Powłoka ochronna ścian:</h2>
      <ul className="check-list">
        <li>malowanie ciekłe,</li>
        <li>malowanie proszkowe.</li>
      </ul>
    </>
  );
};

export const DriversDeskContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Pulpity maszynisty",
          h3Content: [
            "Pulpit to element wykonywany jako konstrukcja aluminiowa dostosowana do wymogów klienta.",
          ],
        }}
        image={driversDesk}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>wytrzymała konstrukcja</li>
        <li>pełne wsparcie konstrukcyjno-technologiczne</li>
        <li>design dostosowywany do wymogów klienta</li>
      </ul>
      <h2>Podstawowe dane techniczne:</h2>
      <p>
        Konstrukcja wyposażona w otwory rewizyjne, przygotowana do montażu
        dodatkowych akcesoriów, takich jak monitory LCD, urządzenia do
        sterowania i pomiaru pracy lokomotywy czy wiązki kablowe. W zależności
        od konstrukcji oraz wymagań klienta, design może uwzględniać elementy
        wykonywane z laminatu (GRP), a także przygotowanie oznakowania dla
        urządzeń sterowniczo-pomiarowych.
      </p>
      <p>
        Produkt oferowany na zasadzie „build to print” lub z pełnym wsparciem
        konstrukcyjno–technologicznym na każdym etapie projektowania.
      </p>
    </>
  );
};

export const CeilingsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Sufity",
          h3Content: [
            "Konstrukcje sufitów wykonywane są ze stopów aluminium jako konstrukcje przenoszące obciążenia lub elementy tylko dekoracyjne.",
          ],
        }}
        image={ceilings}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
        <li>autobus</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>plug and play</li>
        <li>dodatkowa izolacja termiczna</li>
        <li>układy informacji pasażerów</li>
        <li>elementy wspierające osoby z niepełnosprawnością słuchową</li>
        <li>tłumienie drgań</li>
        <li>układy oświetlenia zgodne z EN, DIN, BS, NF</li>
        <li>układy informacyjne</li>
      </ul>
      <h2>Podstawowe dane techniczne:</h2>
      <p>
        Konstrukcyjnie wyroby przewidują połączenia z innymi urządzeniami
        pojazdu, np. układami klimatyzacji. W standardowej wersji sufity są
        elementami gotowymi do zastosowania w pojeździe na zasadzie plug and
        play. W zależności od kształtu i wymagań estetycznych, sufity wykonywane
        są z sandwich-y, profili, blach aluminiowych lub w połączenia obu
        rozwiązań. W wykonaniu znajdują się również wersje z dodatkową izolacją
        termiczną, akustyczną czy tłumiącą drgania.
      </p>
      <p>
        Konstrukcje sufitowe, jako elementy gotowe do montażu, wyposażone są w
        układy oświetlenia (zgodne z EN, DIN, BS, NF), układy informacji
        pasażerów, układy informacyjne, elementy wspierające osoby z
        niepełnosprawnością słuchową.
      </p>
    </>
  );
};

export const DriversCabDoorContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Drzwi zewnętrzne kabiny maszynisty",
        }}
        image={driversCabDoor}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>PN EN 45545</li>
        <li>blokada okna w dowolnej pozycji</li>
        <li>laminaty od strony wewnętrznej</li>
        <li>solidna konstrukcja</li>
        <li>
          możliwość zastosowania wpuszczanych kieszeni dla klamek i zamków
        </li>
      </ul>
      <h5>Systemy drzwi kabiny maszynisty zakładają 2 możliwości dostaw:</h5>
      <ul className="check-list">
        <li>ościeżnica + skrzydło drzwiowe</li>
        <li>tylko skrzydło drzwiowe</li>
      </ul>
      <p>
        Bazę skrzydeł tworzy rama z profili aluminiowych obłożona poszyciem
        blaszanym od strony zewnętrznej oraz zawierającą specjalne warstwy
        izolacyjne poprawiające parametry termiczne i akustyczne. W zależności
        od potrzeb klienta strona wewnętrzna wykonywana jest z blach lub
        laminatów (poliestrowo-szklanych lub termoformowanych). Kształt
        zewnętrzny wyrobów dostosowany jest do profilu konstrukcji pojazdu.
        Gatunek oraz stan utwardzenia materiałów wykorzystywanych do produkcji
        jest dopasowywany każdorazowo do specyficznych wytrzymałościowych
        wymagań klienta. Powierzchnia płatów (wewnątrz oraz zewnątrz)
        zabezpieczona lakierem w systemie malowania ciekłego lub proszkowego (w
        zależności od technologii produkcji drzwi) wg wymagań i kolorystyki
        klienta. Ościeżnice drzwi wykonywane z materiałów stalowych lub
        aluminiowych. Skrzydła drzwiowe zbudowane z lekkich materiałów
        konstrukcyjnych.
      </p>
      <p>
        W zależności od potrzeb projektu drzwi mogą być bez okna lub wyposażone
        w okno:
      </p>
      <ul className="check-list">
        <li>stałe;</li>
        <li>rozwierane;</li>
        <li>opuszczane;</li>
      </ul>
      <p>
        Charakterystyczną cechą drzwi kabiny maszynisty jest system zamka
        umożliwiający szeroki zakres adaptacji do wymagań projektowych klienta:
      </p>
      <ul className="check-list">
        <li>1- lub 2-klamki od strony zewnętrznej;</li>
        <li>1- lub 2- lub 3-punkty system ryglowania;</li>
        <li>funkcja szczelności ciśnieniowej;</li>
        <li>funkcja antypaniczną;</li>
        <li>
          możliwość zastosowania różnego rodzaju wkładek (od standardowych
          patentowych po systemy specjalne);
        </li>
      </ul>
    </>
  );
};

export const InternalDoorsContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Drzwi wewnętrzne",
        }}
        image={internalDoors}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>lekkie konstrukcje</li>
        <li>możliwość doboru materiału i oszklenia</li>
        <li>nowoczesny design</li>
      </ul>
      <p>
        Przy jednoczesnym zapewnieniu bezpieczeństwa czynnego, jak i biernego
        pasażerów, systemy są łatwe w montażu oraz serwisowaniu. Do produkcji
        systemów drzwi wewnętrznych używamy stopów aluminium oraz materiałów
        nowoczesnych –hybrydowych, łączących własności wielu różnych materiałów,
        pozwalających uzyskiwać niską masę wyrobu, duże parametry
        wytrzymałościowe, jak również barierę przeciwpożarową.
      </p>
      <h4>
        Systemy w zależności od specyfikacji, mogą występować w wersji
        elektrycznej, pneumatycznej lub manualnej. Systemy mogą być satynowe czy
        szczotkowane lub wykończone powłokami malarskimi, elementami
        dekoracyjnymi oraz warstwami HPL.
      </h4>
    </>
  );
};

export const PassengerDoorContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Drzwi pasażerskie odskokowo-przesuwne",
        }}
        image={passengerDoor}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>napęd elektryczny</li>
        <li>system bezpieczeństwa pasażerów (ochrona przed zakleszczeniem)</li>
        <li>napęd pneumatyczny</li>
      </ul>
      <p>
        Jednostkę napędową każdego z systemów stanowi mechanizm drzwi –
        elektryczny lub pneumatyczny – wstępnie skalibrowany, z kompletnym
        systemem przewodów oraz sprawdzonym pod względem funkcjonalnym i
        mechanicznym. Kompaktowa budowa oraz przewidziany przebieg ruchu
        wewnętrznych komponentów umożliwiają szybki i bezproblemowy montaż w
        obrębie obszaru portalu pojazdu oraz redukcję potrzebę regulacji dla
        poprawnego nastawienia. Skrzydła drzwiowe zbudowane z lekkich materiałów
        konstrukcyjnych. Bazę skrzydeł tworzy rama z profili aluminiowych
        obłożona poszyciem blaszanym oraz zawierającą specjalne warstwy
        izolacyjne poprawiające parametry termiczne i akustyczne.
      </p>
      <h4>
        Kształt zewnętrzny dostosowany jest do profilu konstrukcji pojazdu
      </h4>
      <p>
        Gatunek oraz stan utwardzenia materiałów wykorzystywanych do produkcji
        jest dopasowywany każdorazowo do specyficznych wytrzymałościowych
        wymagań klienta. Uszczelnienie systemu tworzy szereg specjalnie
        ukształtowanych profili gumowych zapewniających zabezpieczenie przed
        wnikaniem wody i kurzu do wnętrza pojazdu. Powierzchnia płatów (wewnątrz
        oraz zewnątrz) zabezpieczona jest lakierem w systemie malowania ciekłego
        wg wymagań i kolorystyki klienta.
      </p>
      <p>
        W standardzie okno z pakietem szyb ze szkła bezpiecznego – wklejanych
        lub montowanym na uszczelkę – wykonywane wg wymogów projektu.
      </p>
      <h4>
        Każde drzwi wyposażone są w systemy ochrony przed zakleszczeniem
        pasażera.
      </h4>
      <p>
        W zależności od specyficznych wymagań klienta system wyposażany jest w:
      </p>
      <ul className="check-list">
        <li>przyciski do obsługi drzwi;</li>
        <li>uchwyty wyjścia awaryjnego;</li>
        <li>sygnalizatory dźwiękowe i świetlne;</li>
        <li>stopnie wysuwne podłogowe lub podwoziowe;</li>
      </ul>
    </>
  );
};

export const InternalDoorDriversFenceContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Drzwi wewnętrzne wygrodzenia motorniczego",
        }}
        image={internalDoorDriversFence}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>nowoczesny design</li>
        <li>lekka konstrukcja</li>
        <li>łatwy montaż oraz serwis</li>
        <li>możliwość zastosowania wielu materiałów</li>
        <li>wytrzymały system drzwiowy</li>
      </ul>
      <p>
        Systemy drzwi wewnętrznych spełniają najbardziej zróżnicowane wymogi
        funkcjonalne wymagane przez transport publiczny, przy jednoczesnym
        zapewnieniu najwyższego z możliwych poziomów bezpieczeństwa maszynisty.
        Systemy są łatwe w montażu oraz serwisowaniu.
      </p>
      <p>
        Do produkcji systemów drzwi wewnętrznych używamy stopów aluminium oraz
        materiałów nowoczesnych–hybrydowych, łączących własności wielu różnych
        materiałów, pozwalających uzyskiwać niską masę wyrobu, duże parametry
        wytrzymałościowe, jak również barierę przeciwpożarową. Systemy w
        zależności od specyfikacji, mogą występować w wersji pneumatycznej lub
        manualnej.
      </p>
      <p>
        Systemy drzwi wewnętrznych maszynisty mogą być wykańczane na różne
        sposoby satynowe, szczotkowane lub wykończone powłokami malarskimi,
        elementami dekoracyjnymi oraz warstwami HPL.
      </p>
    </>
  );
};

export const TiltWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Okno uchylne",
        }}
        image={tiltWindow}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h4>
        Okna uchylne mogą być wykonane z profili aluminiowych spawanych lub
        skręcanych ze sobą.
      </h4>
      <p>
        Zarówno stała jak i uchylna szyba może zostać wykonana z bezpiecznego
        szkła hartowanego lub laminowanego. Istnieje również możliwość
        zastosowania okien z szybami pojedynczymi jak i zespolonymi w pakiety.
        Okna z zastosowanymi szybami zespolonymi posiadają podwyższoną
        izolacyjności cieplną oraz korzystne parametry optoenergetyczne.
      </p>
      <h4>
        Jesteśmy producentem okien przeznaczonych do wklejania w pudło pojazdu
        oraz okien z zastosowaniem konstrukcji skręcanych umożliwiających szybką
        wymianę całego okna na pojeździe.
      </h4>
      <p>
        Ramy okien są malowane proszkowo zgodnie z obowiązującymi normami rynku
        kolejowego. Szczelność okien jest realizowana za pomocą silikonowych,
        szczotkowych oraz flokowanych uszczelek wytrzymujących długie lata
        eksploatacji. Szyby w oknach montowane są za pośrednictwem uszczelek lub
        wklejane są zgodnie z normą DIN 6701 w klasie A1. Część opuszczana okna
        może być wyposażona w zamki z możliwością blokady przy pomocy klucza
        konduktorskiego. Wszystkie ramy aluminiowe są spawane zgodnie z
        wymaganiami normy ISO 4063. Okna spełniają wymagania szczelności wodnej
        zgodnie z normami PN-EN 14752 oraz EN 50125. Ruch uchylny okna odbywa
        się za pośrednictwem współpracujących ze sobą profili aluminiowych o
        odpowiednim kształcie przekroju.
      </p>
      <p>
        Dla komfortu pasażerów nasze okna wyposażone są w mechanizm równoważenia
        umożliwiający utrzymanie go w dowolnym położeniu. Design okna zakłada
        otwory odwadniające odprowadzające skropliny wody znajdujące się
        wewnątrz okna na zewnątrz pojazdu.
      </p>
      <h2>Możliwości zastosowania oszklenia:</h2>
      <ul className="check-list">
        <li>szyby hartowane,</li>
        <li>szyby laminowane,</li>
        <li>szyby zespolone,</li>
        <li>szyby niskoemisyjne,</li>
        <li>szyby matowe.</li>
      </ul>
    </>
  );
};

export const DriversCabWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Okno kabiny maszynisty",
        }}
        image={driversCabWindow}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>Niewiarygodny komfort dla maszynisty</li>
        <li>Nowoczesny design</li>
        <li>Prosta obsługa</li>
      </ul>
      <h4>
        Unikatowa kompaktowa konstrukcja w jednej formie zawiera dwa moduły:
      </h4>
      <p>
        Rozwierane aluminiowe okno z zamontowaną szybą zespoloną wyposażone w
        funkcję ogranicznika otwarcia i sprężynę gazową utrzymującą okno w
        pozycji otwartej.
      </p>
      <p>
        Lustro otwierane pneumatycznie oparte na aluminiowej konstrukcji z
        zamontowanym elektrycznie podgrzewanym i sterowanym zwierciadłem.
      </p>
      <h4>
        Całą konstrukcję charakteryzuje estetyczny i nowoczesny design,
        niezawodność oraz estetyka wykonania.
      </h4>
    </>
  );
};

export const HingedWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Okno rozwierane",
        }}
        image={hingedWindow}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
      </ul>
      <h4>Okna rozwierane są wykonane z ram aluminiowych spawanych ze sobą.</h4>
      <p>
        Zarówno stała jak i rozwierana część okna może zostać wykonana z
        bezpiecznego szkła hartowanego lub laminowanego. Istnieje możliwość
        zastosowania okien z szybami pojedynczymi jak i zespolonymi w pakiety.
        Okna z zastosowanymi szybami zespolonymi posiadają podwyższoną
        izolacyjności cieplną oraz korzystne parametry optoenergetyczne.
      </p>
      <p>
        Ramy okien są malowane proszkowo zgodnie z obowiązującymi normami rynku
        kolejowego. Szczelność okien jest realizowana za pomocą gumowych
        uszczelek wytrzymujących długie lata eksploatacji. Szyby w oknach
        montowane są za pośrednictwem uszczelek lub wklejane są zgodnie z normą
        DIN 6701 w klasie A1. Część opuszczana okna może być wyposażona w zamki
        z możliwością blokady przy pomocy klucza konduktorskiego. Wszystkie ramy
        aluminiowe są spawane zgodnie z wymaganiami normy ISO 4063. Okna
        spełniają wymagania szczelności wodnej zgodnie z normami PN-EN 14752
        oraz EN 50125. Ruch rozwierany okna odbywa się za pośrednictwem zawiasów
        aluminiowych o dostosowanych do designu. Zamknięcie okna następuje przy
        pomocy tworzywowej klamki, a za utrzymanie okna w pozycji zamkniętej
        odpowiada zasuwnica. Okno wyposażone jest w sprężynę gazową utrzymującą
        stabilnie okno w maksymalnej pozycji otwartej oraz zamkniętej. Design
        okna zakłada otwory odwadniające odprowadzające skropliny wody
        znajdujące się wewnątrz okna na zewnątrz pojazdu.
      </p>
      <h2>Możliwości zastosowania oszklenia:</h2>
      <ul className="check-list">
        <li>szyby hartowane,</li>
        <li>szyby laminowane,</li>
        <li>szyby zespolone,</li>
        <li>szyby niskoemisyjne,</li>
        <li>szyby matowe.</li>
      </ul>
    </>
  );
};

export const SlidingWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Okno przesuwne",
          h2Content: [
            "Nowoczesny design połączony z niebywałym komfortem oraz bezpieczeństwem podróżujących.",
          ],
        }}
        image={slidingWindow}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>tramwaj</li>
        <li>pociąg</li>
        <li>autobus</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>malowane proszkowo</li>
        <li>blokada na klucz konduktorski</li>
        <li>PN-EN 14752</li>
        <li>DIN 6701 (A1)</li>
        <li>ISO 4063</li>
        <li>EN 50125</li>
      </ul>
      <h4>
        Okna przesuwne zbudowane mogą być wykonane z profili aluminiowych
        spawanych lub skręcanych ze sobą.
      </h4>
      <p>
        Zarówno stała jak i przesuwna szyba może zostać wykonana z bezpiecznego
        szkła hartowanego lub laminowanego. Istnieje również możliwość
        zastosowania okien z szybami pojedynczymi jak i zespolonymi w pakiety.
        Okna z zastosowanymi szybami zespolonymi posiadają podwyższoną
        izolacyjności cieplną oraz korzystne parametry optoenergetyczne.
      </p>
      <p>
        Ramy okien są malowane proszkowo zgodnie z obowiązującymi normami rynku
        kolejowego. Szczelność okien jest realizowana za pomocą silikonowych,
        szczotkowych oraz flokowanych uszczelek wytrzymujących długie lata
        eksploatacji. Szyby w oknach montowane są za pośrednictwem uszczelek lub
        wklejane są zgodnie z normą DIN 6701 w klasie A1. Część opuszczana okna
        może być wyposażona w zamki z możliwością blokady przy pomocy klucza
        konduktorskiego. Wszystkie ramy aluminiowe są spawane zgodnie z
        wymaganiami normy ISO 4063. Okna spełniają wymagania szczelności wodnej
        zgodnie z normami PN-EN 14752 oraz EN 50125. Ruch przesuwny okna odbywa
        się za pośrednictwem tworzywowych prowadników
      </p>
      <p>
        Dla komfortu pasażerów nasze okna wyposażone są w mechanizm równoważenia
        umożliwiający utrzymanie go w dowolnym położeniu. Design okna zakłada
        otwory odwadniające odprowadzające skropliny wody znajdujące się
        wewnątrz okna na zewnątrz pojazdu.
      </p>
    </>
  );
};

export const DropdownSlidingWindowContent = () => {
  return (
    <>
      <ContentHeader
        headerContent={{
          h1Content: "Okno opuszczane",
          h2Content: [
            "Nowoczesny design połączony z niebywałym komfortem oraz bezpieczeństwem podróżujących.",
          ],
        }}
        image={dropdownSlidingWindow}
      />
      <h2>Zastosowanie:</h2>
      <ul className="check-list">
        <li>pociąg</li>
      </ul>
      <h2>Cechy kluczowe:</h2>
      <ul className="check-list">
        <li>malowane proszkowo</li>
        <li>blokada na klucz konduktorski</li>
        <li>PN-EN 14752</li>
        <li>DIN 6701 (A1)</li>
        <li>ISO 4063</li>
        <li>EN 50125</li>
      </ul>
      <h4>
        Okna opuszczane zbudowane są z profili aluminiowych spawanych ze sobą.
      </h4>
      <p>
        Zarówno stała jak i opuszczana szyba może zostać wykonana z bezpiecznego
        szkła hartowanego lub laminowanego. Istnieje również możliwość
        zastosowania okien z szybami pojedynczymi jak i zespolonymi w pakiety.
        Okna z zastosowanymi szybami zespolonymi posiadają podwyższoną
        izolacyjności cieplną oraz korzystne parametry optoenergetyczne.
      </p>
      <p>
        Ramy okien są malowane proszkowo zgodnie z obowiązującymi normami rynku
        kolejowego. Szczelność okien jest realizowana za pomocą silikonowych,
        szczotkowych oraz flokowanych uszczelek wytrzymujących długie lata
        eksploatacji. Szyby w oknach montowane są za pośrednictwem uszczelek lub
        wklejane są zgodnie z normą DIN 6701 w klasie A1. Część opuszczana okna
        może być wyposażona w zamki z możliwością blokady przy pomocy klucza
        konduktorskiego. Wszystkie ramy aluminiowe są spawane zgodnie z
        wymaganiami normy ISO 4063. Okna spełniają wymagania szczelności wodnej
        zgodnie z normami PN-EN 14752 oraz EN 50125. Ruch przesuwny okna w dół
        może odbywać się za pośrednictwem tworzywowych prowadników
      </p>
      <p>
        Dla osiągnięcia maksymalnego poziomu komfortu pasażerów nasze okna
        wyposażone są w mechanizm równoważenia umożliwiający utrzymanie go w
        dowolnym położeniu. Design okna zakłada otwory odwadniające
        odprowadzające skropliny wody znajdujące się wewnątrz okna na zewnątrz
        pojazdu.
      </p>
    </>
  );
};

export const MultipleUnitsEquipmentContent = () => {
  return (
    <>
      <h1 className="section-header">Wyposażenie zespołów trakcyjnych</h1>
      <ul className="check-list">
        <li>Zestaw kołowy</li>
        <li>Systemy pneumatyczne</li>
        <li>Tuleje gumowo – metalowe</li>
        <li>Zawieszenie zespołu napędowego</li>
        <li>Elementy elastyczne sprzęgła</li>
        <li>Sprężyny gumowo – metalowe II stopnia usprężynowania</li>
        <li>Wykładziny podłogowe spełniające normę EN45545</li>
        <li>Stabilizatory</li>
        <li>Łożyska osiowe</li>
        <li>Koła</li>
        <li>Układy wentylacji / HVAC</li>
        <li>Amortyzatory</li>
        <li>Wstawki hamulcowe</li>
        <li>Wibroizolatory</li>
        <li>Gumowo – metalowe odbijaki</li>
        <li>Gumowo – metalowe sworznie wahaczy</li>
        <li>Tuleje czopa skrętu</li>
        <li>Prowadniki trakcyjne</li>
        <li>Sprężyny gumowo – metalowe I stopnia usprężynowania</li>
      </ul>
    </>
  );
};

export const LocomotivesEquipmentContent = () => {
  return (
    <>
      <h1 className="section-header">Wyposażenie lokomotyw</h1>
      <ul className="check-list">
        <li>Zestaw kołowy</li>
        <li>Tuleje gumowo – metalowe</li>
        <li>Łożyska</li>
        <li>Sprężyny gumowo – metalowe II stopnia usprężynowania</li>
        <li>Wykładziny podłogowe spełniające normę EN45545</li>
        <li>Łożyska osiowe</li>
        <li>Koła</li>
        <li>Układy wentylacji / HVAC</li>
        <li>Amortyzatory</li>
        <li>Wstawki hamulcowe</li>
        <li>Prowadniki trakcyjne</li>
        <li>Sprężyny gumowo – metalowe I stopnia usprężynowania</li>
      </ul>
    </>
  );
};
