import warsaw1 from "../../assets/warsaw1.jpg";
import "./company.css";

const Company = () => {
  return (
    <>
      <h1 style={{ textAlign: "center" }}>MVA Industries</h1>
      <div className="company-wrapper">
        <img src={warsaw1} alt="" className="left-image" />
        <div style={{ textAlign: "justify" }} className="right-content">
          <p>
            Witamy w MVA Industries, Twoim zaufanym partnerze w handlu
            międzynarodowym. Jesteśmy firmą zajmującą się hurtowym eksportem i
            importem, specjalizującą się w łączeniu firm na całym świecie z
            produktami wysokiej jakości w konkurencyjnych cenach.
          </p>{" "}
          <p>
            W MVA Industries dokładamy wszelkich starań, aby dostarczać naszym
            klientom innowacyjne rozwiązania inżynieryjne. Dzięki ponad
            15-letniemu doświadczeniu, nasz zespół wykwalifikowanych inżynierów
            i projektantów posiada wiedzę fachową, aby sprostać nawet
            najbardziej złożonym projektom inżynieryjnym. Jesteśmy dumni z tego,
            że jesteśmy na bieżąco z najnowszymi trendami i technologiami w
            branży, co pozwala nam dostarczać naszym klientom najnowocześniejsze
            rozwiązania.
          </p>
          <p>
            Dzięki doświadczeniu w różnych dziedzinach, nasz mały zespół
            zbudował silną sieć dostawców i klientów w wielu krajach i branżach.
            Dzięki temu możemy oferować naszym klientom szeroką gamę produktów,
            w tym dóbr konsumpcyjnych, produktów przemysłowych i surowców.
          </p>
          <p>
            W MVA Industries rozumiemy znaczenie niezawodności i satysfakcji
            klienta. Dlatego jesteśmy zaangażowani w zapewnianie wyjątkowej
            obsługi, od zaopatrzenia w produkty i logistykę do wsparcia
            posprzedażowego.
          </p>
          <p>
            Niezależnie od tego, czy jesteś sprzedawcą detalicznym, który chce
            zdywersyfikować swoją ofertę produktów, czy też producentem
            poszukującym nowych rynków dla swoich produktów, jesteśmy tutaj, aby
            Ci pomóc. Skontaktuj się z nami już dziś, aby dowiedzieć się więcej
            o tym, jak możemy wspierać Twoje potrzeby w zakresie handlu
            międzynarodowego.
          </p>
          <p>MVA Industries to Twój globalny partner w handlu.</p>
        </div>
      </div>
    </>
  );
};

export default Company;
