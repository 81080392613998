import "./boxes.css";
import { useEffect } from "react";

const Boxes = () => {
  useEffect(() => {
    document.querySelectorAll(".box").forEach((box) => {
      box.addEventListener("click", () => {
        window.location.href = box.getAttribute("data-href");
      });
    });
  }, []);

  let hoveredElement = null;

  const onHover = (element) => {
    const divs = document.getElementsByClassName("box");

    if (hoveredElement != null) {
      hoveredElement.classList.remove("hover");
    }

    for (let i = 0; i < divs.length; i++) {
      if (divs[i] !== element) {
        divs[i].classList.add("hover");
      } else {
        divs[i].classList.remove("hover");
      }
    }

    hoveredElement = element;
  };

  const onMouseOut = () => {
    const divs = document.getElementsByClassName("box");

    for (let i = 0; i < divs.length; i++) {
      divs[i].classList.remove("hover");
    }

    hoveredElement = null;
  };

  return (
    <>
      <h1 className="milky">Nasze Produkty</h1>
      <div class="box-wrap">
        <div
          className="box"
          data-href="/valves-and-automation"
          onMouseOver={() => onHover(this)}
          onMouseOut={onMouseOut}
        >
          <p>Armatura Przemysłowa</p>
        </div>
        <div
          className="box"
          data-href="/marine"
          onMouseOver={() => onHover(this)}
          onMouseOut={onMouseOut}
        >
          <p>Przemysł Morski</p>
        </div>
        <div
          className="box"
          data-href="/rail"
          onMouseOver={() => onHover(this)}
          onMouseOut={onMouseOut}
        >
          <p>Przemysł Kolejowy</p>
        </div>
        <div
          className="box"
          data-href="/general-industry"
          onMouseOver={() => onHover(this)}
          onMouseOut={onMouseOut}
        >
          <p>Przemysł Ogólny</p>
        </div>
        {/* <div class="box">
          <p>box</p>
        </div> */}
      </div>
    </>
  );
};

export default Boxes;
