import rail from "../../assets/rail-bg.jpg";
import { Content, MainContent } from "../../components/Content/Content";
import DecoratingImage from "../../components/DecoratingImage";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import {
  CeilingsContent,
  DriversCabDoorContent,
  DriversCabWindowContent,
  DriversDeskContent,
  DropdownSlidingWindowContent,
  ElectricalCabinetsContent,
  HingedWindowContent,
  InteriorWallSystemsContent,
  InternalDoorDriversFenceContent,
  InternalDoorsContent,
  InternalWallsContent,
  LocomotivesEquipmentContent,
  MultipleUnitsEquipmentContent,
  PartitionWallsContent,
  PassengerDoorContent,
  SlidingWindowContent,
  TiltWindowContent,
} from "./RailContents";

const options = [
  {
    header: { headerName: "Wyposażenia wnętrz" },
    content: [
      { name: "Szafy elektryczne", link: "/electrical-cabinets" },
      {
        name: "Ścianki z drzwiami",
        link: "/interior-wall-systems",
      },
      {
        name: "Ściany wewnętrzne",
        link: "/partition-walls",
      },
      {
        name: "Zabudowa wewnętrzna",
        link: "/internal-walls",
      },
      { name: "Pulpity maszynisty", link: "/drivers-desk" },
      { name: "Sufity", link: "/ceilings" },
    ],
  },
  {
    header: { headerName: "Drzwi" },
    content: [
      { name: "Drzwi  kabiny maszynisty", link: "/drivers-cab-door" },
      { name: "Drzwi wewnętrzne", link: "/internal-doors" },
      {
        name: "Drzwi pasażerskie",
        link: "/passenger-door",
      },
      {
        name: "Drzwi wygrodzenia motorniczego",
        link: "/internal-door-drivers-fence",
      },
    ],
  },
  {
    header: { headerName: "Okna" },
    content: [
      { name: "Okno uchylne", link: "/tilt-window" },
      { name: "Okno kabiny maszynisty", link: "/drivers-cab-window" },
      {
        name: "Okno rozwierane",
        link: "/hinged-window",
      },
      { name: "Okno przesuwne", link: "/sliding-window" },
      { name: "Okno opuszczane", link: "/dropdown-sliding-window" },
    ],
  },
  {
    header: { headerName: "Pojazdy kolejowe" },
    content: [
      { name: "Wyposażenie zespołów trakcyjnych", link: "/multiple-units" },
      { name: "Wyposażenie lokomotyw", link: "/locomotives" },
    ],
  },
];

export const RailPage = () => {
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <MainContent heading="Kolej" />
      </Content>
      <Footer />
    </>
  );
};

export const ElectricalCabinetsPage = () => {
  options[0].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <ElectricalCabinetsContent />
      </Content>
      <Footer />
    </>
  );
};

export const InteriorWallSystemsPage = () => {
  options[0].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InteriorWallSystemsContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalWallsPage = () => {
  options[0].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalWallsContent />
      </Content>
      <Footer />
    </>
  );
};

export const PartitionWallsPage = () => {
  options[0].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <PartitionWallsContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversDeskPage = () => {
  options[0].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversDeskContent />
      </Content>
      <Footer />
    </>
  );
};

export const CeilingsPage = () => {
  options[0].content[5].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <CeilingsContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversCabDoorPage = () => {
  options[1].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversCabDoorContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalDoorsPage = () => {
  options[1].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalDoorsContent />
      </Content>
      <Footer />
    </>
  );
};

export const PassengerDoorPage = () => {
  options[1].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <PassengerDoorContent />
      </Content>
      <Footer />
    </>
  );
};

export const InternalDoorDriversFencePage = () => {
  options[1].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <InternalDoorDriversFenceContent />
      </Content>
      <Footer />
    </>
  );
};

export const TiltWindowPage = () => {
  options[2].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <TiltWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const DriversCabWindowPage = () => {
  options[2].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DriversCabWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const HingedWindowPage = () => {
  options[2].content[2].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <HingedWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const SlidingWindowPage = () => {
  options[2].content[3].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <SlidingWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const DropdownSlidingWindowPage = () => {
  options[2].content[4].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <DropdownSlidingWindowContent />
      </Content>
      <Footer />
    </>
  );
};

export const MultipleUnitsEquipmentPage = () => {
  options[3].content[0].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <MultipleUnitsEquipmentContent />
      </Content>
      <Footer />
    </>
  );
};

export const LocomotivesEquipmentPage = () => {
  options[3].content[1].isActive = true;
  return (
    <>
      <Navbar />
      <DecoratingImage image={rail} />
      <Content options={options}>
        <LocomotivesEquipmentContent />
      </Content>
      <Footer />
    </>
  );
};
