import "./contact.css";

const Contact = () => {
  return (
    <div className="row gx-5">
      <div className="col-md-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1220.417360889312!2d20.920886934494813!3d52.282702490803175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eca3bcfc75863%3A0x610bf6a77fb45049!2sNocznickiego%2033%2C%2001-918%20Warszawa!5e0!3m2!1spl!2spl!4v1681765060908!5m2!1spl!2spl"
          className="maps"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="col-md-6 contact-info">
        <div
          className="row"
          style={{ borderBottom: "2px dashed black", marginRight: "10px" }}
        >
          <div className="col-4">
            <p>Adres</p>
          </div>
          <div className="col-8" style={{ padding: "0" }}>
            <p>ul. TOMASZA NOCZNICKIEGO 33/323</p>
            <p>01-918 Warszawa, POLSKA</p>
          </div>
        </div>
        <div
          className="row"
          style={{ borderBottom: "2px dashed black", marginRight: "10px" }}
        >
          <div className="col-4">
            <p>Numer tel.</p>
          </div>
          <div className="col-8" style={{ padding: "0" }}>
            <p>123 456 789</p>
          </div>
        </div>
        <div
          className="row"
          style={{ borderBottom: "2px dashed black", marginRight: "10px" }}
        >
          <div className="col-4">
            <p>Email</p>
          </div>
          <div className="col-8" style={{ padding: "0" }}>
            <p>
              <a
                href="mailto:info@mva-industries.com"
                style={{ textDecoration: "none" }}
              >
                info@mva-industries.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
