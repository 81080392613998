import globeStopValve from "../../assets/1.1.png";
import controlValve from "../../assets/1.2.png";
import highPressureGlobeValve from "../../assets/1.3.png";
import highPressureControlGlobeValve from "../../assets/1.4.png";
import needleValve from "../../assets/1.5.png";
import globeLiftCheckValves from "../../assets/2.1.png";
import swingCheckValves from "../../assets/2.2.png";
import highPressureGlobeValves from "../../assets/2.3.png";
import waferTypeSwingCheckValve from "../../assets/2.4.png";
import gateValves from "../../assets/3.1.png";
import forgedGateValves from "../../assets/3.2.png";
import highPressureGateValve from "../../assets/3.3.png";
import knifeGateValveA from "../../assets/4.1.png";

export const globeStopValveContent = {
  headerContent: {
    h1Content: "Zawór zaporowy prosty",
    h2Content: ["EN 13709"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: globeStopValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Skręcana pokrywa (BB)",
      "Trzpień wznoszony (RS), OS&Y",
      "Grzyb regulacyjny lub perforowany",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 160 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Napęd elektryczny, hydrauliczny lub pneumatyczny",
      "Typ Y",
      "Typ kątowy",
      "Wskaźnik położenia",
      "Przedłużony trzpień",
      "Wewnętrzna nakrętka i wznoszony trzpień IS&RS",
      "Dławnica live-loading (SLSB)",
      "Blokada",
      "Siedliska i uszczelnienia wykonane z elastomerów lub polimerów",
      "Kołnierze lub końcówki do spawania zgodnie z: GOST, DIN, ASME etc.",
      "Inne wykończenie (powłoka malarska) na życzenie klienta",
      "Zawór w komplecie z przeciwkołnierzami, śrubami oraz uszczelkami",
    ],
  },
};

export const controlValveContent = {
  headerContent: {
    h1Content: "Zawory regulacyjne",
    h2Content: ["EN 1349 / EN 60534"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: controlValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Skręcana pokrywa (BB)",
      "Trzpień wznoszony (RS), OS&Y",
      "Grzyb regulacyjny lub perforowany",
      "Siedliska zintegrowane lub spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 160 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Napęd elektryczny, hydrauliczny lub pneumatyczny",
      "Charakterystyka regulacji linowa lub stałoprocentowa",
      "Wielostopniowa regulacja",
      "Typ Y",
      "Typ kątowy",
      "Wskaźnik położenia",
      "Przedłużony trzpień",
      "Dławnica live-loading (SLSB)",
      "Blokada",
      "Siedliska i uszczelnienia wykonane z elastomerów lub polimerów",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
      "Zawór w komplecie z przeciwkołnierzami, śrubami oraz uszczelkami",
    ],
  },
};

export const highPressureGlobeValveContent = {
  headerContent: {
    h1Content: "Zawór zaporowy wysokociśnieniowy",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureGlobeValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Pokrywa samouszczelniająca",
      "Trzpień wznoszony (RS), OS&Y",
      "Średnice powyżej DN50 z odciążonym grzybem",
      "Gniazdo i pierścień grzyba spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: [
      "Ciśnienie do 500bar",
      "Class 1500 do Class 2500",
      "Temperatura do 600 °C",
    ],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Napęd elektryczny, hydrauliczny lub pneumatyczny",
      "Typ Y",
      "Typ kątowy",
      "Wskaźnik położenia",
      "Wydłużony trzpień",
      "Dławnica live-loading (SLSB)",
      "Blokada",
      "Zaworytandemowe",
      "Kołnierze i końcówki do spawania zgodnie z EN, ASME, GOST, DIN, etc.",
      "Siedliska i uszczelnienia wykonane z elastomerów lub polimerów",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
      "Zawór w komplecie z przeciwkołnierzami, śrubami oraz uszczelkami",
    ],
  },
};

export const highPressureControlGlobeValveContent = {
  headerContent: {
    h1Content: "Zawór regulacyjny wysokociśnieniowy",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureControlGlobeValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Pokrywa samouszczelniająca",
      "Trzpień wznoszony (RS), OS&Y",
      "Średnice powyżej DN50 z odciążonym grzybem",
      "Gniazdo i pierścień grzyba spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: [
      "Ciśnienie do 500bar",
      "Class 1500 do Class 2500",
      "Temperatura do 600 °C",
    ],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Napęd elektryczny, hydrauliczny lub pneumatyczny",
      "Typ Y",
      "Typ kątowy",
      "Wskaźnik położenia",
      "Wydłużony trzpień",
      "Dławnica live-loading (SLSB)",
      "Blokada",
      "Charakterystyka liniowa lub stałoprocentowa",
      "Kołnierze i końcówki do spawania zgodnie z EN, ASME, GOST, DIN, etc.",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
      "Zawór w komplecie z przeciwkołnierzami, śrubami oraz uszczelkami",
    ],
  },
};

export const needleValveContent = {
  headerContent: {
    h1Content: "Zawór iglicowy",
    h3Content: ["DN 6 ÷ DN 15", "PN 160 ÷ PN 630"],
  },
  image: needleValve,
  mainContent: {
    Design: [
      "Kuty korpus",
      "Wznoszony trzpień",
      "Iglicowy grzyb",
      "Siedliska zintegrowane lub napawane na pierścieniu zabezpieczonym w korpusie (wkręcany)",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 630 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Siedliska oraz uszczelnienie wykonane z elastycznych materiałów",
      "Końcówki do spawania kielichowego (SW) lub doczołowego (BW)",
      "Końcówki z gwintem zewnętrzyn lub wewnętrznym",
      "Inne wykończenie (powłoka malarska) na życzenie klienta",
    ],
  },
};

export const globeLiftCheckValvesContent = {
  headerContent: {
    h1Content: "Zawór zwrotny grzybkowy",
    h2Content: ["EN 16767"],
    h3Content: ["DN 15 ÷ DN 350", "PN 16 ÷ PN 160"],
  },
  image: globeLiftCheckValves,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Skręcana pokrywa (BC)",
      "Samoczynne działanie",
      "Grzyb na prowadnicy dociskany do gniazda za pomocą sprężyny",
      "Siedliska zintegrowane lub spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 160 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Możliwość wykonania siedlisk oraz uszczelnień z materiałów miękkich",
      "Typ Y",
      "Typ kątowy",
      "Kołnierze lub końcówki do spawania zgodnie z: GOST, DIN, ASME etc.",
      "Inne wykończenie (powłoka malarska) na życzenie klienta",
      "Zawór w komplecie z przeciwkołnierzami, śrubami oraz uszczelkami",
    ],
  },
};

export const swingCheckValvesContent = {
  headerContent: {
    h1Content: "Zawór zwrotny klapowy",
    h2Content: ["EN 16767"],
    h3Content: ["DN 15 ÷ DN 600", "PN 16 ÷ PN 160"],
  },
  image: swingCheckValves,
  mainContent: {
    Design: [
      "Odlewany lub kuty korpus i pokrywa",
      "Skręcana pokrywa (BC)",
      "Konstrukcja anti-blowout",
      "Dysk ma możliwość obrotu wokół własnej osi co zapobiega zużyciu w jednym miejscu",
      "Gniazdo korpusu zintegrowane lub spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 160 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Options: [
      "Gniazda wykonane z materiałów elastycznych",
      "Kołnierze i końcówki do spawania wykonane zgodnie z: EN, DIN, GOST, etc.",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
      "Zawory dostarczane w komplecie z przeciwkołnierzami, uszczelkami i śrubami",
    ],
  },
};

export const highPressureGlobeValvesContent = {
  headerContent: {
    h1Content: "Zawór zwrotny grzybkowy wysokociśnieniowy",
    h3Content: ["DN 10 ÷ DN 200", "PN 250 ÷ PN 500", "Class 1500 ÷ Class 2500"],
  },
  image: highPressureGlobeValves,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Pokrywa samouszczelniająca",
      "Grzyb na prowadnicy dociskany do gniazda za pomocą sprężyny",
      "Dysk ma możliwość obrotu wokół własnej osi co zapobiega zużyciu w jednym miejscu",
      "Gniazdo i pierścień grzyba wykonane ze stali nierdzewnej lub Stellitu",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: [
      "Ciśnienie do 500 bar",
      "Class 1500 ÷ Class 2500",
      "Temperatura do 600 °C",
    ],
    Materials: ["Stal węglowa i stopy wysokotemperaturowe"],
    Options: [
      "Kołnierze i końcówki do spawania wykonane zgodnie z: ASME, EN, DIN, GOST, etc.",
      "Gniazda wykonane z materiałów elastycznych",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
      "Zawory dostarczane w komplecie z przeciwkołnierzami, uszczelkami i śrubami",
    ],
  },
};

export const waferTypeSwingCheckValveContent = {
  headerContent: {
    h1Content: "Zawór zwrotny typ wafer",
    h2Content: ["EN 16767", "BS 1868", "API 594"],
    h3Content: ["DN 15 ÷ DN 600", "PN 16 ÷ PN 40", "Class 150 ÷ Class 2500"],
  },
  image: waferTypeSwingCheckValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus",
      "Pokrywa samouszczelniająca",
      "Grzyb na prowadnicy dociskany do gniazda za pomocą sprężyny",
      "Dysk obraca się wokół własnej osi co zapobiega zużyciu w jednym miejscu",
      "Gniazdo korpusu zintegrowane lub spawane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: [
      "Ciśnienie do PN40 (EN 14341)",
      "Class 150 ÷ Class 2500",
      "Temperatura do 600 °C",
    ],
    Materials: ["Stal węglowa i stopy wysokotemperaturowe"],
    Options: [
      "Końcówki przyłączeniowe zgodnie z: GOST, DIN, etc.",
      "Inne wykończenia (powłoka malarska) na życzenie klienta",
    ],
  },
};

export const gateValvesContent = {
  headerContent: {
    h1Content: "Zasuwa klinowa",
    h2Content: ["EN 1984"],
    h3Content: ["DN 50 ÷ DN 600", "PN 16 ÷ PN 160"],
  },
  image: gateValves,
  mainContent: {
    Design: [
      "Odlewany korpus i pokrywa",
      "Skręcana pokrywa (BB)",
      "Trzpień wznoszony (RS), OS&Y",
      "Klin jednoczęściowy elastyczny, pełny lub dzielony",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 160 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Advantages: [
      "Długa żywotność",
      "Zgodność ze standardami emisji",
      "Łatwa obsługa i konserwacja",
      "Wymiana pakunku trzpienia podczas pracy zaworu",
    ],
  },
};

export const forgedGateValvesContent = {
  headerContent: {
    h1Content: "Kuta zasuwa klinowa",
    h3Content: ["DN 8 ÷ DN 50", "PN 16 ÷ PN 250"],
  },
  image: forgedGateValves,
  mainContent: {
    Design: [
      "Kuty korpus i pokrywa",
      "Skręcana pokrywa (BB)",
      "Wznoszony trzpień (RS), OS&Y",
      "Mocowanie pełnego klina z trzpieniem poprzez gniazdo w kształcie litery T",
      "Siedlisko wprasowywane w korpus",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: ["Ciśnienie do 250 bar", "Temperatura do 600 °C"],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Advantages: [
      "Długa żywotność",
      "Zgodność ze standardami emisji",
      "Łatwa obsługa i konserwacja",
      "Wymiana pakunku trzpienia podczas pracy zaworu",
    ],
  },
};

export const highPressureGateValveContent = {
  headerContent: {
    h1Content: "Zasuwa klinowa PN 250 ÷ PN 400",
    h3Content: [
      "DN 15 (1/2”) ÷ DN 300 (12”)",
      "PN 250 ÷ PN 400",
      "Class 900 ÷ Class 2500",
    ],
  },
  image: highPressureGateValve,
  mainContent: {
    Design: [
      "Kuty lub odlewany korpus i pokrywa",
      "Pokrywa samouszczelniająca",
      "Trzpień wznoszony (RS), OS&Y",
      "Konstrukcja z dzielonym trzpieniem",
      "Pierścienie klina i korpusu 17Cr lub stellitowane",
    ],
    Applications: [
      "Energetyka",
      "Przemysł chemiczny",
      "Petrochemiczny",
      "Rafineryjny",
      "Wodociągi i inne",
    ],
    Media: [
      "W zależności od materiału z jakiego wykonany jest zawór:",
      "woda",
      "para",
      "gaz",
      "olej i pochodne olejów",
      "inne nieagresywne media",
    ],
    Pressure: [
      "Ciśnienie do 400 bar",
      "Class 900 ÷ Class 2500",
      "Temperatura do 600 °C",
    ],
    Materials: ["Stal węglowa, stopy wysokotemperaturowe oraz stal nierdzewna"],
    Advantages: [
      "Długa żywotność",
      "Zgodność ze standardami emisji",
      "Łatwa obsługa i konserwacja",
      "Wymiana pakunku trzpienia podczas pracy zaworu",
    ],
  },
};

export const knifeGateValveAContent = {
  headerContent: {
    h1Content: "Zasuwa nożowa - Typ A",
  },
  image: knifeGateValveA,
  mainContent: {
    IndustrialApplications: [
      "Uzdatnianie wody",
      "Przemysł celulozowo-papierniczy",
      "Tamy i projekty hydrologiczne",
      "Przemysł spożywczy",
      "Przemysł chemiczny",
      "Generacja energii",
    ],
    ProductDescription: {
      header: "Zasuwa nożowa jednokierunkowa",
      description:
        "Jednoczęściowy odlewany korpus z prowadnicami do podparcia klinów bramki i gniazda. Zapewnia wysokie natężenia przepływu przy niskim spadku ciśnienia i obejmuje różne dostępne materiały gniazda i uszczelnienia. Na korpusie zaznaczona jest strzałka wskazująca kierunek przepływu. Ta zasuwa nożowa nadaje się do cieczy zawierających maksymalnie 5% zawieszonych ciał stałych. Jeśli jest używana do suchych ciał stałych w aplikacjach zasilania grawitacyjnego, powinna być zainstalowana ze strzałką na korpusie skierowana w kierunku odwrotnym do kierunku przepływu.",
    },
    Sizes: ["Standardowe średnice: od DN50 do DN1200"],
    WorkingPressures: [
      "DN 50-250 = 10 bar",
      "DN 300-400 = 6 bar",
      "DN 450 = 5 bar",
      "DN 500-600 = 4 bar",
      "DN 700-1200 = 3 bar",
    ],
  },
};
